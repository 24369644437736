import gsap, { Power3 } from 'gsap';

import _Header from '../_app/cuchillo/layout/Header';
import { GetBy } from '../_app/cuchillo/core/Element';
import EventDispatcher from '../_app/cuchillo/core/EventDispatcher';

export default class Header extends _Header {
  static cta = GetBy.id('Cta');

  static options = {
    show: {
      duration: .3,
      delay: 0,
      ease: Power3.easeOut
    },
    hide: {
      duration: .3,
      delay: 0,
      ease: Power3.easeIn
    }
  }

  static directHide() {
    this.isShow = false;
    this.container.style.display = 'none';
    this.cta.style.display = 'none';
    gsap.set(this.container, { opacity: 0 });
    gsap.set(this.cta, { opacity: 0 });
  }

  static directShow() {
    this.isShow = false;
    this.container.style.display = 'block';
    this.cta.style.display = 'block';
    gsap.set(this.container, { opacity: 1 });
    gsap.set(this.cta, { opacity: 1 });
  }

  static hide__effect() {
    EventDispatcher.dispatchEvent(_Header.ON_HIDE);

    gsap.to([this.container, this.cta], {
      opacity: 0,
      duration: this.options.hide.duration,
      delay: this.options.hide.delay,
      ease: this.options.hide.duration.ease,
      onComplete: () => {
        this.isShowing = false;
        this.container.style.display = 'none';
      }
    });
  }

  static show__effect() {
    EventDispatcher.dispatchEvent(_Header.ON_SHOW);

    this.container.style.display = 'block';
    this.cta.style.display = 'block';

    gsap.to([this.container, this.cta], {
      opacity: 1,
      duration: this.options.show.duration,
      delay: this.options.show.delay,
      ease: this.options.show.duration.ease,
      onComplete: () => {
        this.isShowing = false;
      }
    });
  }
}


