import { Basics } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';

class ScrollItem__Stock extends VScroll_Item {
  _products;
  _addPrd;
  _holder;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._sell = e => this.sellProduct(e);
    this._add = () => this.addNewProduct();
    this._addPrd = GetBy.class('__new-prod', __link)[0];
    this._addPrd.addEventListener(Basics.clickEvent, this._add);

    this._holder = GetBy.class('__holder', __link)[0];

    this.getProducts();
    this.getToken();

    this.onShow = () => { };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  getProducts() {
    fetch(`https://starter.cuchillo.tools/wp-json/wp/v2/products?cachebuster=${new Date().getTime()}`)
      .then(response => response.json())
      .then(products => {
        this._products = products;
        this.showProducts();
        Scroll.resize();
      });
  }

  getToken() {
    fetch('https://starter.cuchillo.tools/wp-json/jwt-auth/v1/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      },
      body: JSON.stringify({
        username: 'JSON API USER',
        password: 'test1234-test1234'
      })
    })
      .then(response => {
        return response.json();
      }).then(user => {
        localStorage.setItem('jwt', user.token)
      });
  }

  showProducts() {
    for (let i = 0; i < this._products.length; i++) {
      const prod = this._products[i];

      const div = document.createElement('div');
      div.innerHTML = `${prod.id}: ${prod.title.rendered}. Stock: ${this.stripTags(prod.content.rendered)}`;
      const button = document.createElement('button');
      button.innerHTML = 'Sell product';
      button.addEventListener(Basics.clickEvent, this._sell);

      const li = document.createElement('li');
      li.setAttribute('data-index', i);
      // li.setAttribute('data-id', prod.id);
      li.appendChild(div);
      li.appendChild(button);
      this._holder.appendChild(li);
    }
  }

  clearProducts() {
    const prods = GetBy.selector('li', this._holder);

    for (let i = 0; i < prods.length; i++) {
      const prod = prods[i];
      const btn = GetBy.selector('button', prod)[0];
      btn.removeEventListener(Basics.clickEvent, this._sell);
      prod.remove();
    }
  }

  addNewProduct() {
    this._addPrd.setAttribute('disabled', 'disabled');

    fetch('https://starter.cuchillo.tools/wp-json/wp/v2/products', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
      },
      body: JSON.stringify({
        title: `Product - ${this._products.length + 1}`,
        content: '15',
        status: 'publish'
      })
    })
      .then(response => {
        return response.json();
      }).then(product => {
        this._products.push(product);
        this._addPrd.removeAttribute('disabled');

        this.clearProducts();
        this.showProducts();
        Scroll.resize();
      });
  }

  sellProduct(e) {
    e.target.setAttribute('disabled', 'disabled');

    const { index } = e.target.parentNode.dataset;
    const prod = this._products[index];
    const newStock = parseInt(this.stripTags(prod.content.rendered)) - 1;

    fetch(`https://starter.cuchillo.tools/wp-json/wp/v2/products/${prod.id}?cachebuster=${new Date().getTime()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
      },
      body: JSON.stringify({
        content: newStock.toString()
      })
    })
      .then(response => {
        return response.json();
      }).then(product => {
        this._products[index] = product;
        e.target.removeAttribute('disabled');
        this.clearProducts();
        this.showProducts();
        Scroll.resize();
      });
  }

  dispose() {
    super.dispose();
  }

  stripTags(str) { return str.replace(/(<([^>]+)>)/gi, "") }
}

Scroll._registerClass('widget-stock', ScrollItem__Stock);

// Video tutorial
// https://www.youtube.com/watch?v=_w4Ok-lI48g
