import gsap from 'gsap';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Default from './Default';
import { GetBy } from '../_app/cuchillo/core/Element';

export default class Home extends Default {
  // videobg = GetBy.id('VideoBackground');
  // imagebg = GetBy.id('ImageBackground');
  // hasVideo;

  constructor() {
    super();

    // this.hasVideo = this.videobg && this.videobg.hasAttribute('data-video-home');
  }

  //SHOW
  beforeShow() {
    super.beforeShow();

    document.body.classList.add('--home');

    // if (this.hasVideo) {
    // const src = this.videobg.dataset.videoHome;
    // this.videobg.src = src;
    // gsap.set(this.videobg, { opacity: 1 });

    // this._play = () => {
    // this.videobg.removeEventListener('play', this._play);
    // }
    // this.videobg.addEventListener('play', this._play);
    // this.videobg.play();
    // } else {
    // const src = this.imagebg.dataset.imageHome;
    // this.imagebg.src = src;
    // gsap.set(this.imagebg, { opacity: 1 });
    // }
  }

  afterHide() {
    super.afterHide();

    document.body.classList.remove('--home');

    // if (this.hasVideo) {
    //   this.videobg.removeEventListener('play', this._play);
    //   gsap.set(this.videobg, { opacity: 0 });
    //   this.videobg.pause();
    // } else {
    //   gsap.set(this.imagebg, { opacity: 0 });
    //   this.imagebg.src = '';
    // }
  }
}

ControllerPage._addPage('home', Home);
