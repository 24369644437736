import { Sizes } from "../_app/cuchillo/core/Sizes";
import { Maths } from "../_app/cuchillo/utils/Maths";

export default class InstagramPostStory {
  img;
  _container;
  _post;
  _ctxPost;
  _story;
  _ctxStory;
  _btn1;
  _btn2;
  _img;

  constructor(__containter) {
    this._container = __containter;

    this._download = e => { this.downloadImage(e) };

    this.createCanvas();
  }

  createCanvas() {
    // Generates post canvas
    this._post = document.createElement('canvas');
    this._ctxPost = this._post.getContext('2d');
    this._post.id = 'Instagram__Post';

    this._btn1 = document.createElement('button');
    this._btn1.innerHTML = 'Download image';
    this._btn1.classList.add('post', 'button');
    this._btn1.addEventListener('click', e => this._download(e));
    const a1 = document.createElement('a');

    const div1 = document.createElement('div');
    div1.appendChild(this._post);
    div1.appendChild(this._btn1);
    div1.appendChild(a1);

    // Generates story canvas
    this._story = document.createElement('canvas');
    this._story.id = 'Instagram__Story';
    this._ctxStory = this._story.getContext('2d');

    this._btn2 = document.createElement('button');
    this._btn2.innerHTML = 'Download image';
    this._btn2.classList.add('story', 'button');
    this._btn2.addEventListener('click', e => this._download(e));
    const a2 = document.createElement('a');

    const div2 = document.createElement('div');
    div2.appendChild(this._story);
    div2.appendChild(this._btn2);
    div2.appendChild(a2);

    // Adds items
    this._container.appendChild(div1);
    this._container.appendChild(div2);

    this.resize();
  }

  drawImages(fit) {
    if (!this.img) return;

    const { x: sx, y: sy, w: sw, h: sh } = Maths.fitImageSizeToPlane({ width: this.img.width, height: this.img.height }, { width: this._wStory, height: this._hStory }, fit);
    const { x: px, y: py, w: pw, h: ph } = Maths.fitImageSizeToPlane({ width: this.img.width, height: this.img.height }, { width: this._wPost, height: this._hPost }, fit);

    this._ctxStory.fillStyle = '#FFFFFF';
    this._ctxStory.fillRect(0, 0, this._wStory, this._hStory);
    this._ctxStory.drawImage(this.img, sx, sy, sw, sh);
    this._ctxPost.fillStyle = '#FFFFFF';
    this._ctxPost.fillRect(0, 0, this._wPost, this._hPost);
    this._ctxPost.drawImage(this.img, px, py, pw, ph);
  }

  downloadImage(e) {
    if (!this.img) return;

    const anchor = e.target.nextElementSibling;
    anchor.href = e.target.previousElementSibling.toDataURL('image/jpeg');
    anchor.download = `${Date.now()}.jpeg`;
    anchor.click();
  }

  resize() {
    this._wStory = this._story.offsetWidth * Sizes.RATIO;
    this._hStory = this._story.offsetHeight * Sizes.RATIO;
    this._story.setAttribute('width', this._wStory);
    this._story.setAttribute('height', this._hStory);

    this._wPost = this._post.offsetWidth * Sizes.RATIO;
    this._hPost = this._post.offsetHeight * Sizes.RATIO;
    this._post.setAttribute('width', this._wPost);
    this._post.setAttribute('height', this._hPost);
  }
}