import gsap from 'gsap';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Default from './Default';
import { GetBy } from '../_app/cuchillo/core/Element';

export default class WorkshopType extends Default {
  // videobg = GetBy.id('VideoBackground');
  // imagebg = GetBy.id('ImageBackground');
  // media = GetBy.id('Media');
  // hasVideo;

  constructor() {
    super();

    // this.hasVideo = this.media && this.media.hasAttribute('data-video');
  }

  //SHOW
  // beforeShow() {
  //   super.beforeShow();

  //   if (this.hasVideo) {
  //     const src = this.media.dataset.video;
  //     this.videobg.src = src;
  //     gsap.set(this.videobg, { opacity: 1 });

  //     this._play = () => {
  //       this.videobg.removeEventListener('play', this._play);
  //     }
  //     this.videobg.addEventListener('play', this._play);
  //     this.videobg.play();
  //   } else {
  //     const src = this.media.dataset.image;
  //     this.imagebg.src = src;
  //     gsap.set(this.imagebg, { opacity: 1 });
  //   }
  // }

  // beforeHide() {
  //   super.beforeHide();

  //   if (this.hasVideo) {
  //     this.videobg.removeEventListener('play', this._play);
  //     gsap.set(this.videobg, { opacity: 0 });
  //     this.videobg.pause();
  //   } else {
  //     gsap.set(this.imagebg, { opacity: 0 });
  //     this.imagebg.src = '';
  //   }
  // }
}

ControllerPage._addPage('workshopType', WorkshopType);
