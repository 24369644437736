import { isTouch } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import Header from '../layout/Header';

export default class NotFound extends Page {
  constructor() {
    super();
  }

  //SHOW
  beforeShow() {
    super.beforeShow();
    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isTouch, multiplicator: 1 });
    Keyboard.mountPage(this.id);

    if (this.isFirstTime) {
      Header.directHide();
    } else {
      Header.hide();
    }
  }

  show__effect(__call) {
    if (this.isFirstTime) {
      Wrap.show(() => {
        Preloader.hide(() => { this.afterShow() });
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }

  afterShow() {
    super.afterShow();
    Scroll.start();
    Scroll.show();
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    super.beforeHide();
  }

  hide__effect() {
    Scroll.hide();
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    Scroll.dispose();
    super.afterHide();
  }

  //RESIZE
  resize() {
    super.resize();
  }
}

ControllerPage._addPage('notfound', NotFound);