import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { gsap } from "gsap";
import { Maths } from '../_app/cuchillo/utils/Maths';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { GetBy } from '../_app/cuchillo/core/Element';
import BezierEasing from 'bezier-easing'
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { isSmartphone } from '../_app/cuchillo/core/Basics';

class ScrollItem__SliderCircular extends VScroll_Item {
    _call;
    _slider;
    
    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);
        
        this._slider = new SliderScroll(__link, {
            itemClass: ScrollItem__SliderCircular__Item,
            interaction: true,
            hasScrollbar: false
        });
            
        this._call = () => this.loop();
        this.onShow = () => {
            gsap.ticker.add(this._call);
        };

        this.onHide = () => {
            gsap.ticker.remove(this._call);
        };
    }

    resize(w,h) {
        super.resize(w,h);
        if(this._slider) this._slider.resize();
    }

    loop() {
        if(this._slider) this._slider.loop();
    }

    dispose() {
        if(this._slider) this._slider.dispose();
        super.dispose();
    }
}

class ScrollItem__SliderCircular__Item extends VScroll_Item {
    static easing = BezierEasing(0.12, 0.67, 0.36, 0.93);

    hasMove = false;
    _domProgress;
    _domImage;
    _domFigure;
    isEmpty = false;
    options = {
        default: {
            angle: 60,
            max: 1,
            min: .6,
        },
        desktop: {
            angle:60,
            max:1,
            min:.6
        },
        smartphone: {
            angle:60,
            max:1,
            min:.6
        }
    }

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);
        this._domImage = GetBy.class("image", this.item)[0];
        this._domFigure = GetBy.class("figure", this.item)[0];
        this.isEmpty = !this._domImage;

        this.setupOptions();
    }

    setupOptions() {
        this.options.desktop.angle = this.item.getAttribute("data-angle") !== null? Number(this.item.getAttribute("data-angle")) : this.options.desktop.angle;
        this.options.desktop.max = this.item.getAttribute("data-scale-max") !== null? Number(this.item.getAttribute("data-scale-max")) : this.options.desktop.max;
        this.options.desktop.min = this.item.getAttribute("data-scale-min") !== null? Number(this.item.getAttribute("data-scale-min")) : this.options.desktop.min;
        this.options.smartphone.angle = this.item.getAttribute("data-angle-sm") !== null? Number(this.item.getAttribute("data-angle-sm")) : this.options.desktop.angle;
        this.options.smartphone.max = this.item.getAttribute("data-scale-max-sm") !== null? Number(this.item.getAttribute("data-scale-max-sm")) : this.options.desktop.max;
        this.options.smartphone.min = this.item.getAttribute("data-scale-min-sm") !== null? Number(this.item.getAttribute("data-scale-min-sm")) : this.options.desktop.min;

        if(isSmartphone) {
            this.options.default = this.options.smartphone;
        } else {
            this.options.default = this.options.desktop;
        }
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================

    mouseOver() {}
    mouseDown() {}
    mouseUp() {}
    drawHook() {
        if(!this.isEmpty) {
        const PROGRESS = this.progress - .5;
        let scale;
        
        if(PROGRESS < 0) {
            scale = Math.max(Maths.lerp(this.options.default.max, this.options.default.min, ScrollItem__SliderCircular__Item.easing(-PROGRESS)));
        } else {
            scale = Math.max(Maths.lerp(this.options.default.max, this.options.default.min, ScrollItem__SliderCircular__Item.easing(PROGRESS)));
        }

        const rot = Maths.lerp(0, -this.options.default.angle, PROGRESS);

        this._domImage.style[CSS.transform] = CSS.rotate3D(0,0,1,rot);
        this._domFigure.style[CSS.transform] = CSS.scale3D(scale,scale,scale);
        }
    }

    show() {
        super.show();
    }

    hide() {
        super.hide();
    }
}

Scroll._registerClass('SliderCircular', ScrollItem__SliderCircular);