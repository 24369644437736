import gsap, { Power1, Power2 } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';

class ScrollItem__BillboardProject extends VScroll_Item {
  _bg;
  _title;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._bg = GetBy.class('media-holder', __link)[0];
    gsap.set(this._bg, {
      opacity: 0,
      scaleX: 1.05,
      scaleY: 1.05
    });

    this._title = new SplitText(GetBy.class('__title', __link)[0], {
      type: 'lines, words',
      linesClass: 'line-parent',
      wordsClass: 'line-child'
    }).words;
    gsap.set(this._title, { y: '100%' });

    this.onShow = () => {
      let delay = 0;
      gsap.to(this._bg, {
        opacity: 1,
        scaleX: 1,
        scaleY: 1,
        duration: 1.5,
        delay,
        ease: Power2.easeOut
      });

      delay += .2;
      for (let i = 0; i < this._title.length; i++) {
        const item = this._title[i];
        gsap.to(item, {
          y: 0,
          duration: 1.2,
          delay: delay + i * 0.1,
          ease: Power2.easeOut
        });
      }
    };
    this.onHide = () => {};
    this.onMove = () => {
      let opacity = 1;
      if (this.progress >= .5) opacity = Maths.clamp(Maths.map(this.progress, .5, .9, 1, 0), 0, 1);
      
      gsap.set(this._bg, { opacity });
    }
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
}

Scroll._registerClass('billboard-project', ScrollItem__BillboardProject);
