import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Default from './Default';

export default class Workshop extends Default {
  constructor() {
    super();
  }

  //SHOW
  beforeShow() {
    super.beforeShow();
    document.body.classList.add('--alt');
  }

  afterHide() {
    super.afterHide();
    document.body.classList.remove('--alt');
  }
}

ControllerPage._addPage('workshop', Workshop);