import gsap from "gsap";

import { GetBy } from "../_app/cuchillo/core/Element";

export default class Preloader {
    _container;

    static init() {
        this._container = GetBy.id('Preloader');
    }

    static update(__progress) { }

    static hide(__call) {
        gsap.set(this._container, {
            opacity: 0,
            delay: .2,
            onComplete: () => {
                this._container.style.display = "none";
                if (__call) __call();
            }
        });
    }
}
